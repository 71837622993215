var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"add_user","title":"Add new User","title-class":"font-18","size":"lg","centered":""},on:{"shown":_vm.initialData,"hidden":_vm.resetData},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.closeModal}},[_vm._v("Close")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.tryingToSubmit || _vm.showLoader},on:{"click":_vm.addUser}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.tryingToSubmit),expression:"tryingToSubmit"}],attrs:{"small":""}}),_vm._v(" Submit")],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(!_vm.showLoader)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addUser.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Username","label-for":"formrow-username-input"}},[_c('b-form-input',{class:{
                              'is-invalid': _vm.submitted && _vm.$v.username.$error,
                            },attrs:{"type":"text","aria-describedby":"username-feedback","autocomplete":"new-username","aria-autocomplete":"none"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"username"}}),(_vm.submitted)?_c('validationMessages',{attrs:{"fieldName":'Username',"validationName":_vm.$v.username}}):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Password","label-for":"formrow-password-input"}},[_c('b-input-group',[_c('b-form-input',{class:{
                              'is-invalid': _vm.submitted && _vm.$v.password.$error,
                            },attrs:{"type":_vm.showPassword ? 'text' : 'password',"aria-describedby":"password-feedback","autocomplete":"new-password","aria-autocomplete":"none"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"btn btn-outline-secondary"},on:{"click":_vm.toggleShowPassword}},[_c('i',{class:_vm.showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'})])],1)],1),(_vm.submitted)?_c('validationMessages',{attrs:{"fieldName":'Password',"validationName":_vm.$v.password}}):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Role","label-for":"formrow-role-input"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.role),expression:"role"}],staticClass:"form-select",class:{
                                  'is-invalid': _vm.submitted && _vm.$v.role.$error,
                                },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.role=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.role_options),function(role){return _c('option',{key:role.id,domProps:{"value":role.id}},[_vm._v(_vm._s(role.name))])}),0),(_vm.submitted)?_c('validationMessages',{attrs:{"fieldName":'Role',"validationName":_vm.$v.role}}):_vm._e()],1)],1)]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.csrf_token),expression:"csrf_token"}],attrs:{"type":"hidden","name":"csrf_token"},domProps:{"value":(_vm.csrf_token)},on:{"input":function($event){if($event.target.composing)return;_vm.csrf_token=$event.target.value}}})]):_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"large":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }